import React from 'react'

import Seo from 'components/seo'
import Layout from 'layout/base'
import TrialTemplate from 'templates/trialTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface TrialPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const TrialPage = ({ pageContext, location }: TrialPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type2">
      <Seo
        title={`無料体験について${globalTitle(location.pathname)}`}
        description="ご自宅で成績アップの秘密を体験！最短翌日、スマホでもOK。最短１分でお気軽にお申し込みできます。"
        path={location.pathname}
      />
      <TrialTemplate headingText="無料体験について" />
    </Layout>
  )
}

export default TrialPage
