import React from 'react'
import styled from 'styled-components'
import CourseNav from 'components/section/common/courseNav'
import { rem, fontFamily, color, breakpoints } from 'variables/_index'

export interface CourseSectionProps {
  readonly noProps?: string
}

type CourseSectionPropsClassName = CourseSectionProps & {
  readonly className: string
}

function Component({ className }: CourseSectionPropsClassName) {
  return (
    <div className={className}>
      <h2 className="e-head">コースのご案内</h2>
      <div className="e-nav">
        <CourseNav isLink={true} />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseSectionPropsClassName>`
  padding-top: ${rem(120 / 2)};
  padding-bottom: ${rem(120 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120 / 2)};
    padding-bottom: ${rem(200 / 2)};
  }

  > .e-head {
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(50 / 2)};
    font-weight: 500;
    line-height: 1.36;
    color: ${color.main};
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(46)};
    }
  }

  > .e-nav {
    margin-top: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(40 / 2)};
    }
  }
`

export const CourseSection: (props: CourseSectionProps) => JSX.Element =
  StyledComponent

export default CourseSection
