import React from 'react'
import styled from 'styled-components'
import Head from './atoms/head'
import Illustration from './group/illustration'
import { rem } from 'variables/_index'

export interface MainVisualSectionProps {
  readonly headingText: string
  readonly isWide?: boolean
}

type MainVisualSectionPropsClassName = MainVisualSectionProps & {
  readonly className: string
}

function Component({
  className,
  headingText,
}: MainVisualSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <Head headingText={headingText} />
      </div>
      <div className="e-illustration">
        <Illustration />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<MainVisualSectionPropsClassName>`
  > .e-head {
    padding-top: ${rem(30 / 2)};
  }

  > .e-illustration {
    padding-top: ${rem(40 / 2)};
  }
`

export const MainVisualSection: (props: MainVisualSectionProps) => JSX.Element =
  StyledComponent

export default MainVisualSection
