import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import {
  rem,
  color,
  fontFamily,
  innerWidth,
  breakpoints,
} from 'variables/_index'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const iconRef1 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (iconRef1.current) {
      new Rellax(iconRef1.current, {
        speed: 1.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div
          className={classNames('e-illustration1', inView && 'is-in-view')}
          ref={ref}
        >
          <StaticImage
            src="../../../assets/images/illustration/illustration16-2.png"
            alt=""
          />
        </div>
        <h2 className="e-head">
          Fit NET STUDYの
          <br />
          無料体験とは？
        </h2>
        <div className="e-image">
          <StaticImage src="../../../assets/images/trial/about.png" alt="" />
        </div>
        <div className="e-box">
          <div className="e-box-line1">
            <span className="e-em">最大2回の</span>
            <br />
            お子さまに合わせた
            <br className="u-hidden-lg" />
            個別指導
          </div>
          <div className="e-box-plus">＋</div>
          <div className="e-box-line2">
            プロの学習カウンセリング
            <br className="u-hidden-lg" />＆<br className="u-hidden-lg" />
            アドバイス
          </div>
        </div>
        <div className="e-text">
          ご自宅で気軽に体験できます。
          <br className="u-hidden-sm" />
          無料体験授業を終了後、入会を希望されない場合でも受講料はかかりません。
        </div>
        <div className="e-illustration2" ref={iconRef1}>
          <StaticImage
            src="../../../assets/images/illustration/illustration1-2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

const animationSpeed = '2.2s'
const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  @keyframes trialAboutSticky {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    21% {
      transform: translate(0, 0) rotate(0deg);
    }
    22% {
      transform: translate(0, 0) rotate(-7deg);
    }
    23% {
      transform: translate(0, 0) rotate(0deg);
    }
    24% {
      transform: translate(0, 0) rotate(7deg);
    }
    25% {
      transform: translate(0, 0) rotate(0deg);
    }
    26% {
      transform: translate(0, 0) rotate(-7deg);
    }
    27% {
      transform: translate(0, 0) rotate(0deg);
    }

    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }

  @keyframes trial-about-sm {
    0% {
      transform: translate(${rem(-200)}, ${rem(-80)});
    }

    80% {
      transform: translate(${rem(0)}, ${rem(-3)});
    }

    100% {
      transform: translate(0, 0);
    }
  }

  padding-bottom: ${rem(310 / 2)};
  background-color: ${color.main};
  position: relative;

  @media ${breakpoints.lg} {
    width: ${rem(1000)};
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${rem(200)};
  }

  &::before {
    content: '';
    width: ${rem(750 / 2)};
    height: ${rem(750 / 2)};
    position: absolute;
    top: ${rem(-80 / 2)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${color.main};
    border-radius: 50%;

    @media ${breakpoints.lg} {
      width: ${rem(600)};
      height: ${rem(600 / 2)};
      top: ${rem(-60)};
      border-radius: 50% 50% 0 0;
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60 / 2)};
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(750)};
      padding-top: ${rem(50)};
    }

    > .e-illustration1 {
      width: ${rem(74.02 / 2)};
      height: ${rem(40 / 2)};
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: ${rem(-74.02 / 2 / 2)};
      animation: trialAboutSticky 5s infinite ease-in-out;

      // &.is-in-view {
      //   animation: trial-about-sm ${animationSpeed} ease-in-out 1;
      // }

      @media ${breakpoints.lg} {
        width: ${rem(56.02)};
        height: ${rem(30)};
        margin-left: ${rem(-56.02 / 2)};
      }
    }

    > .e-head {
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(50 / 2)};
      font-weight: 500;
      line-height: 1.44;
      color: #fff;
      text-align: center;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
        line-height: 1.5;
      }
    }

    > .e-image {
      margin-top: ${rem(80 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        font-size: ${rem(80)};
      }

      img {
        width: ${rem(446 / 2)};
        height: ${rem(305 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(446 / 1.5)};
          height: ${rem(305 / 1.5)};
        }
      }
    }

    > .e-box {
      padding: ${rem(60 / 2)} ${rem(20 / 2)};
      background-color: ${color.mainBackground};
      border: 6px solid #fff;
      border-radius: 50px;
      text-align: center;
      position: relative;
      z-index: 3;

      @media ${breakpoints.lg} {
        padding-top: ${rem(50)};
        padding-bottom: ${rem(50)};
      }

      > .e-box-line1,
      > .e-box-line2 {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(40 / 2)};
        font-weight: 500;
        line-height: 1.5;

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
        }

        > .e-em {
          display: inline-block;
          margin-bottom: ${rem(8)};
          border-bottom: 1px solid ${color.main};

          @media ${breakpoints.lg} {
            margin-bottom: ${rem(20)};
          }
        }
      }

      > .e-box-plus {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(65 / 2)};
        font-weight: 500;
        color: ${color.main};

        @media ${breakpoints.lg} {
          font-size: ${rem(40)};
        }
      }

      > .e-box-line2 {
      }
    }

    > .e-text {
      margin-top: ${rem(100 / 2)};
      font-size: ${rem(36 / 2)};
      line-height: 2;
      color: #fff;
      position: relative;
      z-index: 3;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
      }
    }

    > .e-illustration2 {
      width: ${rem(114 / 2)};
      height: ${rem(70 / 2)};
      position: absolute;
      top: calc(100% + ${rem(80 / 2)});
      left: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(89.66)};
        height: ${rem(55)};
        left: ${rem(80)};
        top: calc(100% + ${rem(60)});
      }
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element =
  StyledComponent

export default AboutSection
