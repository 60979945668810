import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Rellax from 'rellax'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import {
  rem,
  color,
  fontFamily,
  innerWidth,
  breakpoints,
  animation,
} from 'variables/_index'

export interface FutureSectionProps {
  readonly noProps?: string
}

type FutureSectionPropsClassName = FutureSectionProps & {
  readonly className: string
}

function Component({ className }: FutureSectionPropsClassName) {
  const iconRef1 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (iconRef1.current) {
      new Rellax(iconRef1.current, {
        speed: 1.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <div
            className={classNames('e-illustration1', inView && 'is-in-view')}
            ref={ref}
          >
            <StaticImage
              src="../../../assets/images/illustration/illustration16-2.png"
              alt=""
            />
          </div>
          <h2 className="e-head-text">
            無料体験の後は
            <br />
            どうなるの？
          </h2>
        </div>
        <div className="e-box">
          1回目または2回目の無料体験の後に、入会する・しないの意向をお伝えください。入会を希望されない場合でも、無料体験の受講料はかかりません。
        </div>
        <h3 className="e-head2">
          無料体験から
          <br />
          入会までの流れ
        </h3>
        <div className="e-steps">
          <div className="e-step">
            <div className="e-step-head">1</div>
            <div className="e-step-body">
              無料体験のお申し込み
              <StaticImage
                className="e-arrow"
                src="../../../assets/images/trial/feature-arrow.png"
                alt=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">2</div>
            <div className="e-step-body">
              最大２回の体験授業＆
              <br className="u-hidden-lg" />
              カウンセリング
              <StaticImage
                className="e-arrow"
                src="../../../assets/images/trial/feature-arrow.png"
                alt=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">3</div>
            <div className="e-step-body">
              入会手続き
              <StaticImage
                className="e-arrow"
                src="../../../assets/images/trial/feature-arrow.png"
                alt=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">4</div>
            <div className="e-step-body">
              入会ガイダンス
              <StaticImage
                className="e-arrow"
                src="../../../assets/images/trial/feature-arrow.png"
                alt=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">5</div>
            <div className="e-step-body">授業スタート！</div>
          </div>
        </div>
        <div className="e-illustration">
          <div className="e-illustration-in" ref={iconRef1}>
            <StaticImage
              src="../../../assets/images/illustration/illustration3-2.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<FutureSectionPropsClassName>`
  @keyframes trial-future-sm {
    0% {
      transform: translate(${rem(200)}, ${rem(-80)});
    }

    80% {
      transform: translate(${rem(0)}, ${rem(-3)});
    }

    100% {
      transform: translate(0, 0);
    }
  }

  padding-top: ${rem(200 / 2)};
  padding-bottom: ${rem(290 / 2)};
  background-color: ${color.main};
  position: relative;

  @media ${breakpoints.lg} {
    width: ${rem(1000)};
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(100)};
    padding-bottom: ${rem(260)};
  }

  &::after {
    content: '';
    width: ${rem(750 / 2)};
    height: ${rem(750 / 2)};
    position: absolute;
    bottom: ${rem(-80 / 2)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${color.main};
    border-radius: 50%;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      height: ${rem(1000)};
      bottom: ${rem(-60)};
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60 / 2)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      width: ${rem(750)};
      padding-top: ${rem(50)};
    }

    > .e-illustration {
      width: ${rem(82.11 / 2)};
      height: ${rem(130 / 2)};
      position: absolute;
      top: calc(100% + ${rem(80 / 2)});
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      @media ${breakpoints.lg} {
        top: calc(100% + ${rem(80)});
        width: ${rem(63.16)};
        height: ${rem(100)};
      }
    }

    > .e-head {
      position: relative;

      > .e-head-text {
        font-family: ${fontFamily.zenKaku};
        font-weight: 500;
        font-size: ${rem(50 / 2)};
        color: #fff;
        line-height: 1.434;
        text-align: center;

        @media ${breakpoints.lg} {
          font-size: ${rem(46)};
        }
      }

      > .e-illustration1 {
        width: ${rem(74.02 / 2)};
        height: ${rem(40 / 2)};
        position: absolute;
        left: 50%;
        margin-left: ${rem(-74.02 / 2 / 2)};
        bottom: calc(100% + ${rem(20)});

        &.is-in-view {
          animation: trial-future-sm ${animation.frameIn.speed}
            ${animation.frameIn.functions} 1;
        }

        @media ${breakpoints.lg} {
          width: ${rem(56.02)};
          height: ${rem(30)};
          margin-left: ${rem(-56.02 / 2)};
        }
      }
    }

    > .e-box {
      margin-top: ${rem(80 / 2)};
      padding: ${rem(100 / 2)} ${rem(40 / 2)};
      border-radius: 50px;
      border: 6px solid ${color.mainBackground};
      color: #fff;
      line-height: 2;

      @media ${breakpoints.lg} {
        margin-top: ${rem(80)};
        padding: ${rem(80)} ${rem(60)};
        font-size: ${rem(18)};
      }
    }

    > .e-head2 {
      margin-top: ${rem(200 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 500;
      font-size: ${rem(46 / 2)};
      line-height: 1.44;
      color: #fff;
      position: relative;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(120)};
        font-size: ${rem(38)};
      }
    }

    > .e-steps {
      margin-top: ${rem(80 / 2)};
      padding: ${rem(100 / 2)} ${rem(40 / 2)};
      border-radius: 50px;
      border: 6px solid ${color.mainBackground};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        padding: ${rem(80)};
      }

      > .e-step {
        display: flex;
        align-items: center;

        &:nth-child(n + 2) {
          margin-top: ${rem(140 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(80)};
          }
        }

        > .e-step-head {
          width: ${rem(80 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-size: ${rem(100 / 2)};
          font-weight: 300;
          color: #fff;
          text-align: center;
          line-height: 1;

          @media ${breakpoints.lg} {
            width: ${rem(72)};
            font-size: ${rem(70)};
          }
        }

        > .e-step-body {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(100% - ${rem(80 / 2)});
          height: ${rem(125 / 2)};
          border-radius: 60px;
          background-color: #fff;
          color: ${color.main};
          font-family: ${fontFamily.zenKaku};
          font-weight: 500;
          font-size: ${rem(36 / 2)};
          line-height: 1.27;
          text-align: center;
          position: relative;

          @media ${breakpoints.lg} {
            width: calc(100% - ${rem(72)});
            height: ${rem(70)};
            font-size: ${rem(22)};
          }

          > .e-arrow {
            width: ${rem(60 / 2)};
            height: ${rem(60 / 2)};
            position: absolute;
            top: calc(100% + ${rem(40 / 2)});

            @media ${breakpoints.lg} {
              width: ${rem(40)};
              height: ${rem(40)};
            }
          }
        }
      }
    }
  }
`

export const FutureSection: (props: FutureSectionProps) => JSX.Element =
  StyledComponent

export default FutureSection
