import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Rellax from 'rellax'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import {
  rem,
  color,
  fontFamily,
  innerWidth,
  breakpoints,
  animation,
} from 'variables/_index'

export interface FlowSectionProps {
  readonly noProps?: string
}

type FlowSectionPropsClassName = FlowSectionProps & {
  readonly className: string
}

function Component({ className }: FlowSectionPropsClassName) {
  const iconRef1 = useRef<HTMLDivElement>(null)
  const iconRef2 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (iconRef1.current) {
      new Rellax(iconRef1.current, {
        speed: 1.5, // -10 ~ 10
        center: true,
      })
    }

    if (iconRef2.current) {
      new Rellax(iconRef2.current, {
        speed: 1.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <div
            className={classNames('e-illustration1', inView && 'is-in-view')}
            ref={ref}
          >
            <StaticImage
              src="../../../assets/images/illustration/illustration16-2.png"
              alt=""
            />
          </div>
          <h2 className="e-head-text">無料体験の流れ</h2>
          <div className="e-time">（約60分間）</div>
        </div>
        <div className="e-image">
          <StaticImage
            src="../../../assets/images/trial/flow.png"
            alt=""
            className=""
          />
        </div>
        <div className="e-steps">
          <div className="e-step">
            <div className="e-step-head">
              <svg
                className="e-icon-first"
                height="102.001"
                viewBox="0 0 161.904 102.001"
                width="161.904"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#fff" transform="translate(-177.1 -209.3)">
                  <path d="m212.734 209.3c-5.2 0-9.309 1.6-12.312 4.7s-5.505 7.6-7.707 13.509c-1 2.9-2.4 7.905-4.1 14.91h-6.206c-3.5.1-5.305.9-5.305 2.6 0 1 .6 1.6 1.9 1.7l6.206-.3a23.58 23.58 0 0 1 2.5.1c-.8 4.5-5.705 38.425-6.006 40.526a1.935 1.935 0 0 0 1.4 2.1 1.881 1.881 0 0 0 2.6-1.3l.4-1.1c.9-5.8 5-33.422 6.206-40.026 2 .1 3.7.1 4.9.1h6.006c3-.1 4.5-.9 4.5-2.5 0-1.2-.9-1.8-2.6-1.8a4.913 4.913 0 0 0 -1.1-.1h-6.807c-.6 0-2 0-4-.1 1.2-7.905 3.3-14.609 6.406-20.013s6.907-8.105 11.411-8.105c.7 0 3.2.2 7.507.5a3.48 3.48 0 0 0 1.9-2.7c.109-1.801-2.494-2.701-7.699-2.701z" />
                  <path
                    d="m223.608 246.02v-.9a13.756 13.756 0 0 0 -.7-3.1 3.23 3.23 0 0 1 .8-2.1 2.465 2.465 0 0 1 1.9-.9 2.09 2.09 0 0 1 1.8 1.4 6.435 6.435 0 0 1 .7 3.1c0 1.1-1.4 7.908-4.3 20.219s-4.3 18.818-4.3 19.519.3 1 .9 1c.4 0 1-.4 1.7-1.2a2.956 2.956 0 0 1 1.9-1.2c1 0 1.5.6 1.5 1.9s-.9 2.4-2.8 3.3a12.052 12.052 0 0 1 -4.1 1.4c-2.1 0-3.2-1.7-3.2-5.1a7.516 7.516 0 0 1 .4-2.4 31.162 31.162 0 0 0 .9-3.6c.3-1.5.8-3.6 1.4-6.306s1.2-5.405 1.8-8.208 1.4-6.306 2.3-10.31 1.4-6.213 1.4-6.514zm6.406-21.32a2.389 2.389 0 0 1 2.7 2.7c0 2.6-.9 4.1-2.7 4.4a9.158 9.158 0 0 1 -1.7.1 2.6 2.6 0 0 1 -1.8-.9 3.247 3.247 0 0 1 -.9-2.4 3.62 3.62 0 0 1 1.4-2.7 4.414 4.414 0 0 1 3-1.2z"
                    transform="translate(.023 -.01)"
                  />
                  <path
                    d="m241.9 287.346 2.1-11.111c1.1-5.706 1.8-9.709 2.2-12.112s.8-5 1.3-7.908a115.423 115.423 0 0 1 3.1-12.412.757.757 0 0 1 -.1-.4v-.5a3.5 3.5 0 0 1 1.7-3.2 2.018 2.018 0 0 1 1.1-.3c1.3 0 2 .9 2 2.8a22.583 22.583 0 0 1 -1.3 5.906 78.449 78.449 0 0 0 -2.1 7.908c.4-.7 1-1.6 1.6-2.6.6-1.1 1.6-2.6 3-4.7a34.248 34.248 0 0 1 5.1-5.806c2.1-1.8 4.2-2.8 6.306-2.8a6.779 6.779 0 0 1 4.9 1.7 2.037 2.037 0 0 1 .6 1.5 2.089 2.089 0 0 1 -.8 1.6 2.344 2.344 0 0 1 -1.7.7 3.69 3.69 0 0 1 -1.7-.5 3.746 3.746 0 0 0 -1.8-.5c-.7 0-1.7.5-3.2 1.6q-8.708 6.306-14.414 25.224-1.5 5.1-1.8 6.907c-.2 1.3-.4 2.3-.5 3.1-.9 4.9-2.3 7.407-4.2 7.407-.992.199-1.392-.302-1.392-1.503z"
                    transform="translate(.037 .004)"
                  />
                  <path
                    d="m293.714 243.9c-3 0-4.6 1.8-4.6 5.305a18.223 18.223 0 0 0 1.6 7.307c1.1 2.5 2 4.5 2.8 6.006 3 5.906 4.4 10.41 4.4 13.513a11.853 11.853 0 0 1 -2.8 8.008 12.077 12.077 0 0 1 -8.208 3.9h-.6q-5.1 0-7.507-6.306a.757.757 0 0 1 -.1-.4v-.5a2.273 2.273 0 0 1 .6-1.6 2.579 2.579 0 0 1 1.3-.8h.5a1.8 1.8 0 0 1 1.5.7c.4.5.7 1 1.1 1.6a9.069 9.069 0 0 0 1.1 1.6 2.543 2.543 0 0 0 2.1.7 6.638 6.638 0 0 0 5-1.9 6.154 6.154 0 0 0 1.8-4.3 12.329 12.329 0 0 0 -.7-4.4 71.323 71.323 0 0 0 -3.9-8.808c-3.1-5.806-4.7-10.21-4.7-13.413a12.6 12.6 0 0 1 2.4-7.908 8.4 8.4 0 0 1 6.907-3.1 9.9 9.9 0 0 1 4.7 1.1c2.5 1.4 3.7 3 3.7 4.8s-.7 2.7-2.2 2.7a1.614 1.614 0 0 1 -1.3-.6c-.4-.4-.7-.9-1.1-1.3a5.022 5.022 0 0 0 -3.792-1.904z"
                    transform="translate(.06 .003)"
                  />
                  <path
                    d="m322.008 244.516a42.7 42.7 0 0 0 -6.706.5c-1.2-.1-1.9-.7-1.9-1.6a2.7 2.7 0 0 1 .7-1.7 2.747 2.747 0 0 1 1.8-1l6.807.2c.1-.6.3-1.4.4-2.3s.4-2.2.7-3.9a21.594 21.594 0 0 1 1.5-4.7q.9-2.1 2.4-2.1c1 0 1.5.6 1.5 1.7v.6c-.9 3.8-1.6 7.307-2.2 10.61h6.706a12.011 12.011 0 0 1 4.4.5 1.725 1.725 0 0 1 .8 1.4c0 1.1-.7 1.8-2 2.1a17.119 17.119 0 0 1 -4 .3q-3 0-6.606-.3-.9 4.5-3.9 17.116c-2 8.308-3 13.713-3 16.115s.3 4 .8 4.8a2.446 2.446 0 0 0 2 .7 3.853 3.853 0 0 0 2.4-.8 7.7 7.7 0 0 0 1.8-1.8 14.56 14.56 0 0 1 1.6-1.8 2.507 2.507 0 0 1 1.8-.8 1.694 1.694 0 0 1 1.9 1.9c0 .6-.6 1.7-1.9 3.2a21.765 21.765 0 0 1 -3.5 3.4 7.767 7.767 0 0 1 -4.7 1.2c-2.1 0-3.4-.4-4.2-1.1-1.6-1.4-2.4-3.6-2.4-6.706a41.311 41.311 0 0 1 .5-5.605l.9-5.305c.6-2.6 1.1-4.9 1.6-6.907s.9-3.7 1.2-5.2.8-3.4 1.3-5.806c.699-2.508 1.099-4.71 1.499-6.912z"
                    transform="translate(.08 -.007)"
                  />
                </g>
                <path
                  d="m7723.019-17022.463h161.9"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="4"
                  transform="translate(-7723.019 17122.463)"
                />
              </svg>
            </div>
            <div className="e-step-box">
              <div className="e-step-box-head">
                1｜ガイダンス
                <br className="u-hidden-lg" />
                <span className="e-small">（１回目のみ）</span>
              </div>
              <ul className="e-step-box-lists">
                <li className="e-step-box-list">
                  お子さまの勉強のお悩みについて丁寧にお聞きします。
                </li>
                <li className="e-step-box-list">
                  コースの特徴をご説明します。
                </li>
              </ul>
            </div>
            <div className="e-illustration2" ref={iconRef1}>
              <StaticImage
                src="../../../assets/images/illustration/illustration8-2.png"
                alt=""
              />
            </div>
            <div className="e-arrow">
              <StaticImage
                src="../../../assets/images/trial/flow-arrow.png"
                alt=""
                className=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">
              <svg
                className="e-icon-second"
                height="112"
                viewBox="0 0 234.04 112"
                width="234.04"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#fff">
                  <path
                    d="m150.1 230.269c-2.98 0-4.471 1.788-4.471 5.266a17.642 17.642 0 0 0 1.59 7.153c1.093 2.484 1.987 4.471 2.782 5.862 2.881 5.762 4.371 10.233 4.371 13.313a11.509 11.509 0 0 1 -2.782 7.849 11.956 11.956 0 0 1 -8.047 3.875h-.6c-3.378 0-5.862-2.086-7.352-6.259a.751.751 0 0 1 -.1-.4v-.5a2.256 2.256 0 0 1 .6-1.59 1.877 1.877 0 0 1 1.292-.7h.5a1.787 1.787 0 0 1 1.49.7c.4.5.7.993 1.093 1.59a9 9 0 0 0 1.093 1.59 2.428 2.428 0 0 0 1.987.7 6.221 6.221 0 0 0 4.868-1.888 6.108 6.108 0 0 0 1.788-4.272 12.609 12.609 0 0 0 -.7-4.371 70.481 70.481 0 0 0 -3.875-8.643c-3.08-5.663-4.57-10.134-4.57-13.213a12.026 12.026 0 0 1 2.384-7.749 8.2 8.2 0 0 1 6.756-2.98 9.823 9.823 0 0 1 4.669 1.093c2.384 1.391 3.676 2.98 3.676 4.769s-.7 2.682-2.186 2.682a1.6 1.6 0 0 1 -1.292-.6 12.586 12.586 0 0 0 -1.093-1.292 5.478 5.478 0 0 0 -3.871-1.985z"
                    transform="translate(-135.5 -184.976)"
                  />
                  <path
                    d="m190.267 265.741c1.093 0 1.59.6 1.59 1.689 0 1.292-1.59 2.782-4.769 4.371s-5.762 2.384-7.849 2.384a8.977 8.977 0 0 1 -4.769-.993 8.069 8.069 0 0 1 -2.583-2.881 17.92 17.92 0 0 1 -1.887-8.644 53.369 53.369 0 0 1 2.682-16.989 38.3 38.3 0 0 1 7.252-13.213c2.98-3.477 6.259-5.265 9.736-5.265a5.119 5.119 0 0 1 4.371 2.285 9.3 9.3 0 0 1 1.59 5.464 16.084 16.084 0 0 1 -3.08 10.034 25.85 25.85 0 0 1 -7.551 6.756 77.27 77.27 0 0 1 -10.332 4.967l-.2 6.656c0 5.166 1.59 7.749 4.669 7.749a10.894 10.894 0 0 0 5.265-1.49c1.788-.993 3.179-1.689 3.974-2.086a4.474 4.474 0 0 1 1.891-.794zm1.093-31.593q0-3.577-2.98-3.577c-1.788 0-3.875 1.59-6.358 4.868a42.771 42.771 0 0 0 -6.06 12.816 22.337 22.337 0 0 0 -.7 2.881c1.192-.7 2.384-1.292 3.676-1.888s2.484-1.192 3.676-1.888a19.438 19.438 0 0 0 6.461-5.36 12.281 12.281 0 0 0 2.285-7.852z"
                    transform="translate(-135.725 -184.98)"
                  />
                  <path
                    d="m211.771 261.767c0 5.464 1.987 8.147 5.961 8.147a7.522 7.522 0 0 0 5.564-2.881c.7-.795 1.292-1.391 1.888-1.987a2.576 2.576 0 0 1 1.788-.894c1.192 0 1.788.5 1.788 1.59 0 1.888-1.192 3.875-3.676 5.862a13.022 13.022 0 0 1 -8.345 2.98 8.113 8.113 0 0 1 -7.054-3.477 13.247 13.247 0 0 1 -2.384-7.749 51.1 51.1 0 0 1 2.98-16.691 46.822 46.822 0 0 1 7.849-14.5c3.278-3.875 6.656-5.862 10.332-5.862 2.682 0 4.371.993 5.266 2.881a14.873 14.873 0 0 1 .7 1.987 2.582 2.582 0 0 0 .993 1.49 3.234 3.234 0 0 1 .4 1.391 2.24 2.24 0 0 1 -.795 1.59 2.327 2.327 0 0 1 -1.689.7c-1.292 0-2.086-.993-2.484-2.881s-1.391-2.881-2.98-2.881c-2.285 0-4.669 1.788-7.153 5.265a48.143 48.143 0 0 0 -6.259 12.816 39 39 0 0 0 -2.69 13.104z"
                    transform="translate(-135.977 -184.981)"
                  />
                  <path
                    d="m264.462 226.8c5.663 0 8.544 4.272 8.544 12.915 0 6.656-1.888 13.909-5.564 21.559-2.484 5.166-5.464 8.842-9.041 11.127a9.975 9.975 0 0 1 -5.564 1.788c-3.378 0-5.862-1.391-7.252-4.073a17.843 17.843 0 0 1 -2.186-8.743 48.017 48.017 0 0 1 2.782-16.293 38.4 38.4 0 0 1 7.65-13.114c3.18-3.477 6.756-5.166 10.631-5.166zm-12.121 14.9a41.754 41.754 0 0 0 -4.868 18.976q0 6.408 3.577 8.643a4.267 4.267 0 0 0 2.484.7c1.888 0 4.073-1.689 6.458-4.967a45.875 45.875 0 0 0 5.961-12.319 41.446 41.446 0 0 0 2.384-12.518c0-3.477-.2-5.663-.6-6.656a4.234 4.234 0 0 0 -1.391-1.888 4.393 4.393 0 0 0 -2.583-.5 7.08 7.08 0 0 0 -3.974 1.49c-2.778 1.59-5.262 4.67-7.448 9.039z"
                    transform="translate(-136.216 -184.984)"
                  />
                  <path
                    d="m312.018 236.836q0-4.471-2.384-4.471c-2.186 0-6.259 6.16-12.419 18.578-2.782 5.663-4.669 9.836-5.762 12.717s-1.888 4.868-2.484 6.06a14.6 14.6 0 0 1 -1.59 2.682 2.257 2.257 0 0 1 -1.788.894 1.239 1.239 0 0 1 -1.192-.5 5.9 5.9 0 0 1 -.2-1.689c0-.795 1.192-7.948 3.577-21.261s3.775-20.366 4.173-21.062a3.822 3.822 0 0 1 .993-1.292 2.965 2.965 0 0 1 1.59-.3 2.006 2.006 0 0 1 1.788.894 3.121 3.121 0 0 1 .7 1.59 6.707 6.707 0 0 1 -.2 1.391 20.707 20.707 0 0 1 -.993 2.98c-1.49 4.073-2.782 9.935-4.173 17.485l-.996 5.468c.7-1.49 1.49-3.179 2.384-5.166s2.186-4.967 3.875-8.743a55.036 55.036 0 0 1 5.762-10.134c2.682-3.875 5.265-5.862 7.55-5.862 4.073 0 6.16 3.279 6.16 9.935a53.969 53.969 0 0 1 -1.093 9.339q-1.043 5.514-2.384 12.22a66.732 66.732 0 0 0 -1.391 10.928l-.5 2.484a2.4 2.4 0 0 1 -.3 1.391c-.2.3-.795.4-1.788.4a3.553 3.553 0 0 1 -2.086-.5 1.906 1.906 0 0 1 -.4-1.888l4.471-26.923a34.583 34.583 0 0 0 1.1-7.645z"
                    transform="translate(-136.487 -184.986)"
                  />
                  <path
                    d="m357.02 230.693 4.371-19.371c.3-1.391.993-4.967 2.186-10.728s1.987-9.735 2.583-12.02l.2-1.093a2.773 2.773 0 0 1 .795-1.987 2.1 2.1 0 0 1 1.689-.795 1.815 1.815 0 0 1 1.987 2.086v.4c0 1.49-1.093 7.053-3.179 16.788s-3.477 16.589-4.073 20.563-1.788 9.536-3.577 16.589c-1.788 7.152-2.881 11.921-3.278 14.4-.5 2.483-.894 4.57-1.292 6.358a22.9 22.9 0 0 0 -.6 5.166 18.636 18.636 0 0 0 .795 5.166 2.726 2.726 0 0 1 -.993 1.788 2.459 2.459 0 0 1 -1.59.7c-.795 0-1.49-.894-1.987-2.781a15.811 15.811 0 0 1 -.894-4.669 25.586 25.586 0 0 1 .5-4.371c-1.192 3.676-2.782 6.457-4.769 8.245a10.888 10.888 0 0 1 -7.65 2.682c-3.179 0-5.464-1.291-6.756-3.874a17.2 17.2 0 0 1 -1.987-7.649c0-9.139 1.788-16.987 5.265-23.344a37.438 37.438 0 0 1 4.272-6.556 28.361 28.361 0 0 1 4.769-4.073 12.933 12.933 0 0 1 7.252-1.887 6.446 6.446 0 0 1 5.961 4.267zm-3.279 14.4c.5-2.483.795-3.973.894-4.669a24.21 24.21 0 0 0 .2-3.377 8.76 8.76 0 0 0 -1.391-4.57 4.223 4.223 0 0 0 -3.875-2.185c-2.98 0-5.663 1.887-8.047 5.563a47.472 47.472 0 0 0 -5.862 13.013 44.756 44.756 0 0 0 -2.186 12.517c0 3.377.993 5.762 2.881 7.252a3.879 3.879 0 0 0 2.484.7c2.086 0 4.371-1.788 6.855-5.464a52.437 52.437 0 0 0 5.464-10.331 44.282 44.282 0 0 0 2.583-8.442z"
                    transform="translate(-136.789 -184.7)"
                  />
                </g>
                <path
                  d="m7723.019-17022.463h234.04"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="4"
                  transform="translate(-7723.018 17132.463)"
                />
              </svg>
            </div>
            <div className="e-step-box">
              <div className="e-step-box-head">2｜体験授業</div>
              <ul className="e-step-box-lists">
                <li className="e-step-box-list">
                  勉強のお悩みや学校の学習進度に合わせて最適な授業をご用意。
                </li>
              </ul>
            </div>
            <div className="e-illustration3" ref={iconRef2}>
              <StaticImage
                src="../../../assets/images/illustration/illustration12-2.png"
                alt=""
              />
            </div>
            <div className="e-arrow">
              <StaticImage
                src="../../../assets/images/trial/flow-arrow.png"
                alt=""
                className=""
              />
            </div>
          </div>
          <div className="e-step">
            <div className="e-step-head">
              <svg
                className="e-icon-third"
                height="112"
                viewBox="0 0 179.704 112"
                width="179.704"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#fff">
                  <path
                    d="m167.2 245.8a42.144 42.144 0 0 0 -6.619.494c-1.185-.1-1.877-.593-1.877-1.581a2.669 2.669 0 0 1 .692-1.679 2.711 2.711 0 0 1 1.778-.988l6.619.2c.1-.593.2-1.383.4-2.272.1-.889.4-2.173.692-3.853a15.356 15.356 0 0 1 1.482-4.544q.889-2.075 2.371-2.075c.988 0 1.482.593 1.482 1.679v.593c-.889 3.655-1.581 7.212-2.173 10.472h6.619c2.371 0 3.754.2 4.248.494a1.7 1.7 0 0 1 .79 1.383 2.111 2.111 0 0 1 -1.877 2.075 16.9 16.9 0 0 1 -3.952.3q-2.964 0-6.52-.3c-.593 2.964-1.877 8.595-3.754 16.795-1.976 8.2-2.964 13.436-2.964 15.807s.3 3.952.79 4.643a2.414 2.414 0 0 0 1.976.692 4.02 4.02 0 0 0 2.371-.79 7.6 7.6 0 0 0 1.778-1.778c.494-.593 1.087-1.185 1.581-1.778a2.435 2.435 0 0 1 1.679-.79 1.672 1.672 0 0 1 1.877 1.877c0 .593-.593 1.679-1.877 3.161a21.48 21.48 0 0 1 -3.458 3.359 7.54 7.54 0 0 1 -4.544 1.186c-1.976 0-3.359-.3-4.149-.988-1.581-1.383-2.371-3.557-2.371-6.619a42.161 42.161 0 0 1 .494-5.532l.889-5.236c.593-2.569 1.087-4.841 1.482-6.718.494-1.877.79-3.655 1.186-5.038.3-1.482.79-3.359 1.284-5.73.682-2.574 1.175-4.846 1.575-6.921z"
                    transform="translate(-158.7 -199.565)"
                  />
                  <path
                    d="m195.74 286.139c0 1.382-1.087 2.072-3.359 2.072-1.087 0-1.581-.493-1.581-1.48s2.964-15.2 8.99-42.631c4.446-20.921 7.311-35.131 8.5-42.434l.4-1.184c.3-.888.79-1.382 1.581-1.382a2.77 2.77 0 0 1 2.865 2.27c-.2 1.086-.593 2.763-.988 4.737-.4 2.072-.889 4.342-1.383 6.908s-1.087 5.428-1.778 8.388c-.593 3.059-1.284 6.02-1.877 9.178q-1.037 4.589-2.964 14.21c-1.383 6.414-2.47 11.743-3.458 16.085 6.224-12.829 12.151-19.243 17.684-19.243a5.492 5.492 0 0 1 5.028 2.667 11.164 11.164 0 0 1 1.679 5.921 47.566 47.566 0 0 1 -1.186 8.98l-1.482 7.7c-1.679 8.487-2.47 13.52-2.47 15.1l.1 3.454a2.525 2.525 0 0 1 -.79 1.875 2.785 2.785 0 0 1 -2.075.691 2.057 2.057 0 0 1 -1.877-.888 3.181 3.181 0 0 1 -.593-1.776 6.527 6.527 0 0 1 .1-1.48l5.335-29.7a16.947 16.947 0 0 0 .3-3.355c0-3.059-.889-4.638-2.569-4.638-3.26 0-7.607 4.835-13.139 14.408a73.374 73.374 0 0 0 -5.733 11.336c-.692 2.072-1.383 4.539-2.075 7.2a27.662 27.662 0 0 0 -.988 5.23z"
                    transform="translate(-159.13 -199.1)"
                  />
                  <path
                    d="m246.9 247.244v-.889a13.578 13.578 0 0 0 -.692-3.063 3.4 3.4 0 0 1 .79-2.075 2.294 2.294 0 0 1 1.877-.889 1.959 1.959 0 0 1 1.778 1.383 6.351 6.351 0 0 1 .692 3.063c0 1.087-1.383 7.706-4.248 19.758-2.766 12.053-4.248 18.474-4.248 19.067 0 .692.3.988.889.988.4 0 .988-.4 1.679-1.186a2.714 2.714 0 0 1 1.877-1.186c.988 0 1.482.593 1.482 1.877s-.889 2.371-2.766 3.26a11.265 11.265 0 0 1 -4.05 1.383c-2.075 0-3.161-1.679-3.161-5.038a6.483 6.483 0 0 1 .4-2.272 26.24 26.24 0 0 0 .889-3.556c.3-1.482.79-3.557 1.383-6.125s1.186-5.236 1.778-8.1c.593-2.766 1.383-6.125 2.272-10.077a55.712 55.712 0 0 0 1.379-6.323zm6.323-20.944a2.358 2.358 0 0 1 2.667 2.667c0 2.569-.889 4.05-2.667 4.347a9.038 9.038 0 0 1 -1.679.1 2.562 2.562 0 0 1 -1.778-.889 3.13 3.13 0 0 1 -.889-2.272 3.573 3.573 0 0 1 1.383-2.667 4.05 4.05 0 0 1 2.964-1.286z"
                    transform="translate(-159.763 -199.527)"
                  />
                  <path
                    d="m264.3 287.926 2.075-10.966c1.087-5.631 1.778-9.583 2.173-11.855.4-2.371.79-4.94 1.284-7.706a107.852 107.852 0 0 1 3.063-12.151.747.747 0 0 1 -.1-.4v-.494a3.2 3.2 0 0 1 1.679-3.063 1.906 1.906 0 0 1 .988-.3c1.284 0 1.877.889 1.877 2.766a23.117 23.117 0 0 1 -1.284 5.829 53.2 53.2 0 0 0 -1.976 7.8c.4-.692.889-1.581 1.581-2.569.593-1.087 1.581-2.569 2.865-4.544a26.9 26.9 0 0 1 5.038-5.73 9.219 9.219 0 0 1 6.224-2.667 6.691 6.691 0 0 1 4.841 1.679 2.011 2.011 0 0 1 .593 1.482 2.227 2.227 0 0 1 -.79 1.581 2.314 2.314 0 0 1 -1.679.692 3.642 3.642 0 0 1 -1.679-.494 3.7 3.7 0 0 0 -1.778-.494c-.692 0-1.679.494-3.161 1.581-5.73 4.149-10.373 12.349-14.127 24.7a59.1 59.1 0 0 0 -1.778 6.817c-.2 1.284-.4 2.272-.494 3.063-.889 4.841-2.272 7.212-4.149 7.212-.886-.09-1.286-.682-1.286-1.769z"
                    transform="translate(-160.113 -199.704)"
                  />
                  <path
                    d="m326.565 245.39 4.347-19.243c.3-1.382.988-4.934 2.173-10.658s1.976-9.671 2.569-11.941l.2-1.086a2.754 2.754 0 0 1 .79-1.974 2.087 2.087 0 0 1 1.679-.789 1.8 1.8 0 0 1 1.976 2.072v.395c0 1.48-1.087 7.007-3.161 16.678s-3.458 16.48-4.05 20.427-1.778 9.474-3.557 16.48c-1.778 7.105-2.865 11.842-3.26 14.309-.494 2.467-.889 4.539-1.284 6.316a22.731 22.731 0 0 0 -.593 5.132 18.5 18.5 0 0 0 .79 5.132 2.707 2.707 0 0 1 -.988 1.776 2.446 2.446 0 0 1 -1.581.691c-.79 0-1.482-.888-1.976-2.763a15.694 15.694 0 0 1 -.889-4.638 25.388 25.388 0 0 1 .494-4.342c-1.186 3.651-2.766 6.414-4.742 8.191a10.834 10.834 0 0 1 -7.607 2.664c-3.161 0-5.434-1.283-6.718-3.849a17.071 17.071 0 0 1 -1.976-7.6c0-9.079 1.778-16.875 5.236-23.191a37.186 37.186 0 0 1 4.248-6.513 28.193 28.193 0 0 1 4.742-4.046 12.87 12.87 0 0 1 7.212-1.875 6.41 6.41 0 0 1 5.926 4.245zm-3.26 14.309c.494-2.467.79-3.947.889-4.638a24.033 24.033 0 0 0 .2-3.355 8.7 8.7 0 0 0 -1.383-4.539 4.2 4.2 0 0 0 -3.855-2.167c-2.964 0-5.631 1.875-8 5.526a47.136 47.136 0 0 0 -5.829 12.928 44.421 44.421 0 0 0 -2.173 12.434c0 3.355.988 5.724 2.865 7.2a3.86 3.86 0 0 0 2.47.691c2.075 0 4.347-1.776 6.817-5.428a52.074 52.074 0 0 0 5.434-10.263 43.96 43.96 0 0 0 2.565-8.388z"
                    transform="translate(-160.593 -199.107)"
                  />
                </g>
                <path
                  d="m7723.019-17022.463h175.381"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="4"
                  transform="translate(-7718.691 17132.462)"
                />
              </svg>
            </div>
            <div className="e-step-box">
              <div className="e-step-box-head">3｜学習カウンセリング</div>
              <ul className="e-step-box-lists">
                <li className="e-step-box-list">
                  授業の様子を踏まえて、指導のプロから学習アドバイス。
                </li>
                <li className="e-step-box-list">
                  2回目の体験日を予約（1回目のみ）。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<FlowSectionPropsClassName>`
  @keyframes trial-flow-sm {
    0% {
      transform: translate(${rem(-200)}, ${rem(-80)});
    }

    80% {
      transform: translate(${rem(0)}, ${rem(-3)});
    }

    100% {
      transform: translate(0, 0);
    }
  }

  padding-top: ${rem(260 / 2)};
  padding-bottom: ${rem(160 / 2)};
  background-color: ${color.main};
  position: relative;

  @media ${breakpoints.lg} {
    width: ${rem(1000)};
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(100)};
    padding-bottom: ${rem(200)};
  }

  &::after {
    content: '';
    width: ${rem(750 / 2)};
    height: ${rem(750 / 2)};
    position: absolute;
    bottom: ${rem(-80 / 2)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${color.main};
    border-radius: 50%;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      height: ${rem(1000)};
      bottom: ${rem(-60)};
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60 / 2)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      width: ${rem(750)};
      padding-top: ${rem(50)};
    }

    > .e-head {
      font-family: ${fontFamily.zenKaku};
      font-weight: 500;
      font-size: ${rem(50 / 2)};
      color: #fff;
      position: relative;
      text-align: center;
      line-height: 1.44;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
        line-height: 1.44;
      }

      > .e-head-text {
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
        text-align: center;
        line-height: inherit;
      }

      > .e-time {
        text-align: center;

        @media ${breakpoints.lg} {
          font-size: ${rem(30)};
        }
      }

      > .e-illustration1 {
        width: ${rem(74.02 / 2)};
        height: ${rem(40 / 2)};
        position: absolute;
        left: 50%;
        margin-left: ${rem(-74.02 / 2 / 2)};
        bottom: calc(100% + ${rem(20)});

        &.is-in-view {
          animation: trial-flow-sm ${animation.frameIn.speed}
            ${animation.frameIn.functions} 1;
        }

        @media ${breakpoints.lg} {
          width: ${rem(56.02)};
          height: ${rem(30)};
          margin-left: ${rem(-56.02 / 2)};
        }
      }
    }

    > .e-image {
      width: ${rem(445 / 2)};
      height: ${rem(275 / 2)};
      margin-top: ${rem(80 / 2)};
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        margin-top: ${rem(80)};
        width: ${rem(369)};
        height: ${rem(226)};
      }

      img {
      }
    }

    > .e-steps {
      margin-top: ${rem(120 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(100)};
      }

      > .e-step {
        position: relative;

        &:nth-child(n + 2) {
          margin-top: ${rem(310 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(240)};
          }
        }

        > .e-step-head {
          text-align: center;

          > .e-icon-first {
            width: ${rem(161.9 / 2)};
            height: ${rem(100 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(127.5)};
              height: ${rem(73)};
            }
          }

          > .e-icon-second {
            width: ${rem(234.04 / 2)};
            height: ${rem(110 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(182.03)};
              height: ${rem(80.04)};
            }
          }

          > .e-icon-third {
            width: ${rem(179.7 / 2)};
            height: ${rem(110 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(139.77)};
              height: ${rem(80)};
            }
          }
        }

        > .e-step-box {
          margin-top: ${rem(60 / 2)};
          padding: ${rem(60 / 2)} ${rem(50 / 2)};
          border: 6px solid #fff;
          border-radius: 50px;
          background-color: ${color.mainBackground};
          position: relative;
          z-index: 3;

          @media ${breakpoints.lg} {
            margin-top: ${rem(40)};
            padding: ${rem(50)} ${rem(60)};
          }

          > .e-step-box-head {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            padding-bottom: ${rem(40 / 2)};
            font-family: ${fontFamily.zenKaku};
            font-size: ${rem(40 / 2)};
            font-weight: 500;
            color: ${color.main};
            position: relative;

            @media ${breakpoints.lg} {
              padding-bottom: ${rem(30)};
              font-size: ${rem(30)};
            }

            &::after {
              content: '';
              width: 100%;
              height: 2px;
              border-bottom: 2px dotted ${color.main};
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }

            > .e-small {
              @media ${breakpoints.lg} {
                font-size: ${rem(20)};
              }
            }
          }

          > .e-step-box-lists {
            margin-top: ${rem(40 / 2)};
            margin-bottom: 0;

            @media ${breakpoints.lg} {
              margin-top: ${rem(30)};
              margin-left: ${rem(60)};
            }

            .e-step-box-list {
              line-height: 2;
              margin: 0;
              padding: 0;

              @media ${breakpoints.lg} {
                font-size: ${rem(18)};
              }
            }
          }
        }

        > .e-illustration2 {
          width: ${rem(72 / 2)};
          height: ${rem(50 / 2)};
          position: absolute;
          left: ${rem(40 / 2)};
          top: calc(100% + ${rem(60 / 2)});

          @media ${breakpoints.lg} {
            width: ${rem(58.1)};
            height: ${rem(40)};
            top: calc(100% + ${rem(60)});
            left: ${rem(80)};
          }
        }

        > .e-illustration3 {
          width: ${rem(56 / 2)};
          height: ${rem(86 / 2)};
          position: absolute;
          right: ${rem(40 / 2)};
          top: calc(100% + ${rem(60 / 2)});

          @media ${breakpoints.lg} {
            width: ${rem(45.86)};
            height: ${rem(70)};
            top: calc(100% + ${rem(60)});
            right: ${rem(80)};
          }
        }

        > .e-arrow {
          width: ${rem(160 / 2)};
          height: ${rem(160 / 2)};
          position: absolute;
          top: calc(100% + ${rem(110 / 2)});
          left: 50%;
          transform: translateX(-50%);

          @media ${breakpoints.lg} {
            width: ${rem(110)};
            height: ${rem(110)};
            top: calc(100% + ${rem(110)});
          }
        }
      }
    }
  }
`

export const FlowSection: (props: FlowSectionProps) => JSX.Element =
  StyledComponent

export default FlowSection
