import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import {
  rem,
  color,
  fontFamily,
  innerWidth,
  breakpoints,
  animation,
} from 'variables/_index'

export interface HowSectionProps {
  readonly noProps?: string
}

type HowSectionPropsClassName = HowSectionProps & {
  readonly className: string
}

function Component({ className }: HowSectionPropsClassName) {
  const iconRef1 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '0px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (iconRef1.current) {
      new Rellax(iconRef1.current, {
        speed: 1.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div
          className={classNames('e-illustration1', inView && 'is-in-view')}
          ref={ref}
        >
          <StaticImage
            src="../../../assets/images/illustration/illustration5-2.png"
            alt=""
          />
        </div>
        <h2 className="e-head">申し込み方法は？</h2>
        <div className="e-image">
          <StaticImage src="../../../assets/images/trial/how.png" alt="" />
        </div>
        <div className="e-box">
          <div className="e-box-line1">
            申し込みフォームから
            <br className="u-hidden-lg" />
            最短１分で完了！
          </div>
          <div className="e-box-line2">
            <span className="e-em">最短翌日から体験スタート</span>
            <br />
            <span className="e-em">スマホ・タブレット・PC</span>
            <br className="u-hidden-lg" />
            <span className="e-em">いずれかがあればOK</span>
          </div>
        </div>
        <div className="e-pop">
          <svg
            className="e-pop-left"
            height="40.895"
            viewBox="0 0 21.789 40.895"
            width="21.789"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m-13304.133-7628.125 20 40"
              fill="none"
              stroke="#00c8ff"
              strokeDasharray="2 4"
              strokeWidth="2"
              transform="translate(13305.027 7628.572)"
            />
          </svg>
          お気軽にお申し込みください
          <svg
            className="e-pop-right"
            height="40.895"
            viewBox="0 0 21.789 40.895"
            width="21.789"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m-13284.133-7628.125-20 40"
              fill="none"
              stroke="#00c8ff"
              strokeDasharray="2 4"
              strokeWidth="2"
              transform="translate(13305.027 7628.572)"
            />
          </svg>
        </div>
        <div className="e-illustration2" ref={iconRef1}>
          <StaticImage
            src="../../../assets/images/illustration/illustration18-2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<HowSectionPropsClassName>`
  @keyframes trial-how-sm {
    0% {
      transform: translate(${rem(200)}, ${rem(-80)});
    }

    80% {
      transform: translate(${rem(0)}, ${rem(-3)});
    }

    100% {
      transform: translate(0, 0);
    }
  }

  padding-bottom: ${rem(310 / 2)};
  background-color: ${color.mainBackground};
  position: relative;

  @media ${breakpoints.lg} {
    width: ${rem(1000)};
    margin-left: auto;
    margin-right: auto;
    padding-bottom: ${rem(200)};
  }

  &::before {
    content: '';
    width: ${rem(750 / 2)};
    height: ${rem(750 / 2)};
    position: absolute;
    top: ${rem(-80 / 2)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${color.mainBackground};
    border-radius: 50%;

    @media ${breakpoints.lg} {
      width: ${rem(600)};
      height: ${rem(600 / 2)};
      top: ${rem(-60)};
      border-radius: 50% 50% 0 0;
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(60 / 2)};
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(750)};
      padding-top: ${rem(50)};
    }

    > .e-illustration1 {
      width: ${rem(80 / 2)};
      height: ${rem(50 / 2)};
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: ${rem(-80 / 2 / 2)};

      &.is-in-view {
        animation: trial-how-sm ${animation.frameIn.speed}
          ${animation.frameIn.functions} 1;
      }

      @media ${breakpoints.lg} {
        width: ${rem(56)};
        height: ${rem(35)};
        margin-left: ${rem(-56 / 2)};
      }
    }

    > .e-head {
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(50 / 2)};
      font-weight: 500;
      line-height: 1.44;
      color: ${color.main};
      text-align: center;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
        line-height: 1.5;
      }
    }

    > .e-image {
      margin-top: ${rem(80 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        font-size: ${rem(80)};
      }

      img {
        width: ${rem(350 / 2)};
        height: ${rem(290 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(242)};
          height: ${rem(200)};
        }
      }
    }

    > .e-box {
      padding: ${rem(60 / 2)} ${rem(20 / 2)} ${rem(80 / 2)};
      background-color: #fff;
      border-radius: 50px;
      border: 6px solid ${color.main};
      text-align: center;
      position: relative;
      z-index: 3;

      @media ${breakpoints.lg} {
        padding-top: ${rem(50)};
        padding-bottom: ${rem(50)};
      }

      > .e-box-line1,
      > .e-box-line2 {
        font-family: ${fontFamily.zenKaku};
        font-weight: 500;
        line-height: 1.5;
        text-align: center;

        > .e-em {
          display: inline-block;
          border-bottom: 1px solid ${color.main};
          line-height: 1.9;
        }
      }

      > .e-box-line1 {
        font-size: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
        }
      }

      > .e-box-line2 {
        margin-top: ${rem(60 / 2)};
        font-size: ${rem(38 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(22)};
        }
      }
    }

    > .e-pop {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: ${rem(40 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(38 / 2)};
      font-weight: 500;
      line-height: 2;
      color: ${color.main};
      position: relative;
      z-index: 3;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        font-size: ${rem(26)};
      }

      > .e-pop-left,
      > .e-pop-right {
        flex-shrink: 0;
        content: '';
        width: ${rem(20)};
        height: ${rem(40)};

        @media ${breakpoints.lg} {
          width: ${rem(20)};
          height: ${rem(40)};
        }
      }

      > .e-pop-left {
        margin-right: ${rem(10)};
      }

      > .e-pop-right {
        margin-left: ${rem(10)};
      }
    }

    > .e-illustration2 {
      width: ${rem(104 / 2)};
      height: ${rem(77 / 2)};
      position: absolute;
      top: calc(100% + ${rem(80 / 2)});
      right: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(74.26)};
        height: ${rem(55)};
        right: ${rem(80)};
        top: calc(100% + ${rem(60)});
      }
    }
  }
`

export const HowSection: (props: HowSectionProps) => JSX.Element =
  StyledComponent

export default HowSection
