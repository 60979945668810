import React from 'react'
import styled from 'styled-components'
import { rem, color, fontFamily, breakpoints } from 'variables/_index'

export interface HeadProps {
  readonly headingText: string
}

type HeadPropsClassName = HeadProps & {
  readonly className: string
}

function Component({ className, headingText }: HeadPropsClassName) {
  return <h1 className={className}>{headingText}</h1>
}

const StyledComponent = styled(Component)<HeadPropsClassName>`
  text-align: center;
  font-family: ${fontFamily.zenKaku};
  font-size: ${rem(50 / 2)};
  font-weight: 500;
  color: ${color.main};

  @media ${breakpoints.lg} {
    font-size: ${rem(46)};
  }
`

export const Head: (props: HeadProps) => JSX.Element = StyledComponent

export default Head
