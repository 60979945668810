import React from 'react'
import styled from 'styled-components'
import CallToActionSection from 'components/section/common/callToActionSection'
import MainVisualSection from 'components/section/trial/mainVisualSection'
import AboutSection from 'components/section/trial/aboutSection'
import HowSection from 'components/section/trial/howSection'
import FlowSection from 'components/section/trial/flowSection'
import FutureSection from 'components/section/trial/futureSection'
import CourseSection from 'components/section/trial/courseSection'
import { breakpoints, rem } from 'variables/_index'

export interface TrialTemplateProps {
  readonly headingText: string
}

type TrialTemplatePropsClassName = TrialTemplateProps & {
  readonly className: string
}

function Component({ className, headingText }: TrialTemplatePropsClassName) {
  return (
    <div className={className}>
      <MainVisualSection headingText={headingText} />
      <div className="e-about">
        <AboutSection />
        <HowSection />
      </div>
      <CallToActionSection
        contactId="cta_content1_trial"
        isDisplayDocument={false}
        isDisplayAbout={false}
        isDisplayBox={false}
      />
      <div className="e-flow">
        <FlowSection />
      </div>
      <CallToActionSection
        contactId="cta_content2_trial"
        isDisplayDocument={false}
        isDisplayAbout={false}
      />
      <div className="e-future">
        <FutureSection />
      </div>
      <div className="e-course">
        <CourseSection />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<TrialTemplatePropsClassName>`
  > .e-about {
    @media ${breakpoints.lg} {
      margin-bottom: ${rem(160)};
    }
  }

  > .e-flow {
    margin-bottom: ${rem(200 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(160)};
      margin-bottom: ${rem(180)};
    }
  }

  > .e-future {
    margin-bottom: ${rem(200 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(160)};
      margin-bottom: ${rem(180)};
    }
  }

  > .e-course {
    @media ${breakpoints.lg} {
      margin-bottom: ${rem(100)};
    }
  }
`

export const TrialTemplate: (props: TrialTemplateProps) => JSX.Element = StyledComponent

export default TrialTemplate
