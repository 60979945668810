import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import { rem, breakpoints } from 'variables/_index'

export interface IllustrationProps {
  readonly noProps?: string
}

type IllustrationPropsClassName = IllustrationProps & {
  readonly className: string
}

function Component({ className }: IllustrationPropsClassName) {
  const teacherRef1 = useRef<HTMLDivElement>(null)
  const iconRef1 = useRef<HTMLDivElement>(null)
  const iconRef2 = useRef<HTMLDivElement>(null)
  const iconRef3 = useRef<HTMLDivElement>(null)
  const iconRef4 = useRef<HTMLDivElement>(null)
  const iconRef5 = useRef<HTMLDivElement>(null)
  const iconRef6 = useRef<HTMLDivElement>(null)
  const iconRef7 = useRef<HTMLDivElement>(null)
  const iconRef8 = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // new Rellax(teacherRef1.current, {
    //   speed: 1.5, // -10 ~ 10
    //   center: false,
    // })

    if (iconRef1.current) {
      new Rellax(iconRef1.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef2.current) {
      new Rellax(iconRef2.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef3.current) {
      new Rellax(iconRef3.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef4.current) {
      new Rellax(iconRef4.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef5.current) {
      new Rellax(iconRef5.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef6.current) {
      new Rellax(iconRef6.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef7.current) {
      new Rellax(iconRef7.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (iconRef8.current) {
      new Rellax(iconRef8.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }
  }, [])

  return (
    <div className={className}>
      <StaticImage
        className="e-desk"
        src="../../../../assets/images/trial/mv1.png"
        alt=""
        placeholder="none"
        loading="eager"
      />
      <div ref={teacherRef1} className="e-teacher1">
        <StaticImage
          className="e-teacher1-image"
          src="../../../../assets/images/trial/mv2.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef1} className="e-icon1">
        <StaticImage
          className="e-icon1-image"
          src="../../../../assets/images/mv/illustration12.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef2} className="e-icon2">
        <StaticImage
          className="e-icon2-image"
          src="../../../../assets/images/mv/illustration16.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef3} className="e-icon3">
        <StaticImage
          className="e-icon3-image"
          src="../../../../assets/images/mv/illustration3-2.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef4} className="e-icon4">
        <StaticImage
          className="e-icon4-image"
          src="../../../../assets/images/mv/illustration5.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef5} className="e-icon5">
        <StaticImage
          className="e-icon5-image"
          src="../../../../assets/images/mv/illustration1-2.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef6} className="e-icon6">
        <StaticImage
          className="e-icon6-image"
          src="../../../../assets/images/mv/illustration8.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef7} className="e-icon7">
        <StaticImage
          className="e-icon7-image"
          src="../../../../assets/images/mv/illustration15.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
      <div ref={iconRef8} className="e-icon8">
        <StaticImage
          className="e-icon8-image"
          src="../../../../assets/images/mv/illustration18.png"
          alt=""
          placeholder="none"
          loading="eager"
        />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<IllustrationPropsClassName>`
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(${rem(24)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  padding-top: ${rem(242 / 2)};
  padding-bottom: ${rem(194 / 2)};
  text-align: center;
  position: relative;
  z-index: 1;

  @media ${breakpoints.lg} {
    padding-top: ${rem(182)};
    padding-bottom: ${rem(90)};
  }

  > .e-desk {
    width: ${rem(424 / 2)};
    margin-left: ${rem(132)};
    position: relative;
    z-index: 20;
    opacity: 0;
    animation: fadeUp 2.8s ease 0s forwards;

    @media ${breakpoints.lg} {
      width: ${rem(330)};
      margin-left: ${rem(232)};
    }
  }

  > .e-teacher1 {
    position: absolute;
    z-index: 10;
    width: ${rem(450 / 2)};
    bottom: ${rem(72 / 2)};
    right: calc(50% - ${rem(114 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(324)};
      bottom: ${rem(40)};
      right: calc(50% - ${rem(54)});
    }

    > .e-teacher1-image {
      opacity: 0;
      animation: fadeUp 2.8s ease 1.6s forwards;
    }
  }

  > .e-icon1,
  > .e-icon2,
  > .e-icon3,
  > .e-icon4,
  > .e-icon5,
  > .e-icon6,
  > .e-icon7,
  > .e-icon8 {
    position: absolute;
    z-index: 30;

    > .e-icon1-image,
    > .e-icon2-image,
    > .e-icon3-image,
    > .e-icon4-image,
    > .e-icon5-image,
    > .e-icon6-image,
    > .e-icon7-image,
    > .e-icon8-image {
      opacity: 0;
      animation: fadeUp 2.8s ease 2.2s forwards;
    }
  }

  // 定規
  > .e-icon1 {
    width: ${rem(52 / 2)};
    bottom: calc(50% + ${rem(270 / 2)});
    right: calc(50% + ${rem(214 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(40)};
      bottom: calc(50% + ${rem(162)});
      right: calc(50% + ${rem(206)});
    }
  }

  // ふせん（赤）
  > .e-icon2 {
    width: ${rem(46 / 2)};
    bottom: calc(50% + ${rem(134 / 2)});
    right: calc(50% + ${rem(262 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(36)};
      bottom: calc(50% + ${rem(78)});
      right: calc(50% + ${rem(248)});
    }
  }

  // ノート
  > .e-icon3 {
    width: ${rem(84 / 2)};
    top: calc(50% - ${rem(160 / 2)});
    right: calc(50% + ${rem(162 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(64)};
      top: calc(50% - ${rem(110)});
      right: calc(50% + ${rem(158)});
    }
  }

  // ふせん（青）
  > .e-icon4 {
    width: ${rem(56 / 2)};
    bottom: calc(50% + ${rem(332 / 2)});
    left: calc(50% + ${rem(158 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(36)};
      bottom: calc(50% + ${rem(232)});
      left: calc(50% + ${rem(82)});
    }
  }

  // コンパス
  > .e-icon5 {
    width: ${rem(46 / 2)};
    bottom: calc(50% + ${rem(214 / 2)});
    left: calc(50% + ${rem(190 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(30)};
      bottom: calc(50% + ${rem(142)});
      left: calc(50% + ${rem(128)});
    }
  }

  // 修正テープ
  > .e-icon6 {
    width: ${rem(62 / 2)};
    bottom: calc(50% + ${rem(132 / 2)});
    left: calc(50% + ${rem(232 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(34)};
      bottom: calc(50% + ${rem(116)});
      left: calc(50% + ${rem(194)});
    }
  }

  // ふせん（黄）
  > .e-icon7 {
    width: ${rem(46 / 2)};
    top: calc(50% + ${rem(154 / 2)});
    right: calc(50% - ${rem(10 / 2)});

    @media ${breakpoints.lg} {
      width: ${rem(32)};
      top: calc(50% + ${rem(118)});
      right: calc(50% - ${rem(10 / 2)});
    }
  }

  // ボールペン
  > .e-icon8 {
    width: ${rem(68 / 2)};
    top: calc(50% + ${rem(206 / 2)});
    left: calc(50% - ${rem(10)});

    @media ${breakpoints.lg} {
      width: ${rem(50)};
      top: calc(50% + ${rem(152)});
      left: calc(50% - ${rem(12)});
    }
  }
`

export const Illustration: (props: IllustrationProps) => JSX.Element =
  StyledComponent

export default Illustration
